// import Logo from '../../../assets/images/logoMaedeDeus.png';
import { FC, ReactElement, useEffect, useState } from 'react';
import kiiry_svg from '../../../assets/images/logo.png';
import FormConfirm from './formConfirm';
import { ConfirmData } from '@/types/ConfirmData';
import { ConfirmacaoTokenTypeEnum } from '@/config/enum';
// import CreateFormConfirm from './createFormConfirm';
import useConfirmData from './hooks/useConfirmData';
import { SelectInput, Form as RHForm } from '@/components';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
	confirmData?: (data?: ConfirmData) => void;
	token: string;
	tokenType: string;
}
	
const UseTerms: FC<Props> = ({ confirmData = () => {}, token, tokenType }) => {
	const navigate = useNavigate();
    const location = useLocation();
    const enableClientsSelect = location?.state?.enableClientsSelect;
	const [loading, setLoading] = useState<boolean>(true);
	const {
		clients,
		defaultValues,
		getConfirmData,
		getClients,
	} = useConfirmData();
	
	useEffect(() => {
		const fetchClient = async () => {
			try {
				await getClients();
			} catch (error) {
				console.error('Error fetching data:', error);
			}
			setLoading(false);
		};
		const fetchConfirmData = async () => {
			try {
				if (token) {
					await getConfirmData(token);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			}
			setLoading(false);
		};
		if (tokenType !== ConfirmacaoTokenTypeEnum.User) {
			fetchClient();
		} else {
			fetchConfirmData();
		}
	}, [tokenType, token]);

	if (loading) {
		return (
			<div className="card-disabled">
				<div className="card-portlets-loader"></div>
			</div>
		);
	}

	
	let clientOptions: Array<ReactElement> = [
		<option value="">
			Carregando...
		</option>
	];
	if (clients != null && clients.length > 0) {
		if (tokenType === ConfirmacaoTokenTypeEnum.Client && enableClientsSelect !== true) {
			const intToken = parseInt(token);
			const client = clients.find((c) => c.id === intToken);
			clientOptions = [
				<option
					key={client.id}
					value={client.id}
					defaultChecked={true}
				>
					{client.corporate_reason}
				</option>
			];
		} else {
			clientOptions = [
				<option value="" key={0}>
					Selecione
				</option>,
				...clients.map((client) => (
					<option
						key={client.id}
						value={client.id}
					>
						{client.corporate_reason}
					</option>
				)),
			];
		}
	}

	let client;
	if (tokenType === ConfirmacaoTokenTypeEnum.Client && clients != null) {
		client = clients.find(c => c.id.toString() === token);
	}

	const client_id = defaultValues?.id || client?.id;
	const logo = defaultValues?.logo || client?.logo_image || kiiry_svg;
	const terms_link_url = defaultValues?.terms_link_url || client?.terms_link_url || '';
	return (
		<div className="content-use-terms">
			<div className="img-principal">
				<img className="logo" src={logo} alt="logo" />
			</div>
			<div className="formulario-use-terms">
				<div style={{ alignSelf: 'start', margin: '0 3%', width: '94%', overflow: 'hidden' }}>
					<p>
						Que bom que você se interessou pelo benefício!
						{tokenType === ConfirmacaoTokenTypeEnum.None || enableClientsSelect ? ' Nós já nos conhecemos?' : ''}
					</p>
					{(tokenType === ConfirmacaoTokenTypeEnum.None || enableClientsSelect) && (
						<RHForm onSubmit={() => {}} defaultValues={{client: client_id}}>
							<SelectInput
								label="Local de Trabalho"
								name="client"
								className="react-select"
								containerClass="mb-3"
								disabled={clientOptions.length === 1}
								onChangeCustom={value => {
									const clientId = value && value.length > 0 ? value : 'novo';
									navigate(`/confirmacao/${clientId}`, { state: { enableClientsSelect: true }});
								}}
							>
								{clientOptions}
							</SelectInput>
						</RHForm>
					)}
					{tokenType !== ConfirmacaoTokenTypeEnum.None && (
						<>
							<p>Para saber mais sobre o funcionamento siga os passos:</p>
							<p>
								PASSO 1: saiba tudo sobre o BENEFÍCIO clicando aqui:{' '}
								<a href={terms_link_url} target="_blank" rel="noopener noreferrer">{terms_link_url}</a>
							</p>
							<p>PASSO 2: preencha os dados solicitados abaixo e finalize seu cadastro.</p>
							<p>PASSO 3: ENVIE AS CONTAS e comece a economizar dinheiro tendo descontos todos os meses na contas de energia.</p>
						</>
					)}
				</div>
				<div className="campos_inputs">
					{tokenType !== ConfirmacaoTokenTypeEnum.None && (
						<h4>Para receber o benefício, informe os dados abaixo</h4>
					)}
					<div className="inputs_container">
						{tokenType !== ConfirmacaoTokenTypeEnum.None ? (
							<FormConfirm
								confirmData={(e) => {
									confirmData(e);
								}}
								client_id={client_id}
								token={token}
								tokenType={tokenType}
								defaultValues={defaultValues}
							/>							
						) : (
							<FormConfirm
								confirmData={(e) => {
									confirmData(e);
								}}
								client_id={client_id}
								token={token}
								tokenType={tokenType}
								defaultValues={defaultValues}
							/>		
							// AQUI É O FORMULARIO GIGANTE QUE FOI SUBSTITUIDO PELO PREENCHIMENTO SIMPLIFICADO ATRAVES DO FORMCONFIRM
							// <CreateFormConfirm
							// 	token={token}
							// 	tokenType={tokenType}
							// />
						)}
					</div>
					{tokenType !== ConfirmacaoTokenTypeEnum.None && (
						<div>
							<span className="logo-l\g">
								<img src={kiiry_svg} />
							</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default UseTerms;
