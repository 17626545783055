import { Form } from 'react-bootstrap';
import { Control, Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { FileType, FileUploader } from '..';

type FileUploaderInputProps = {
	type: 'image' | 'file';
	id?: string;
	name: string;
	className?: string;
	containerClass?: string;
	label?: string;
	placeholder?: string;
	bsPrefix?: string;
	helpText?: string;
	readOnly?: boolean;
	disabled?: boolean;
	errors?: any;
	control?: Control<any>;
	register?: RegisterOptions;
	maxSize?: number;
	maxFiles?: number;
};

export default function FileUploaderInput({
	name,
	id,
	className,
	containerClass,
	label,
	placeholder,
	maxSize,
	helpText,
	errors,
	register,
	type,
	maxFiles,
	...props
}: FileUploaderInputProps) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => {
				return (
					<Form.Group className={containerClass ?? ''}>
						{label && <Form.Label>{label}</Form.Label>}
						<FileUploader
							name={name}
							maxSize={maxSize}
							invalid={Boolean(fieldState?.error?.message)}
							onFileUpload={(file: FileType[]) => {
								maxFiles !== undefined && maxFiles == 1
									? field.onChange(file[0])
									: field.onChange(file);
							}}
							maxFiles={maxFiles}
						/>
						{helpText && <p className="text-muted font-14">{helpText}</p>}
						{fieldState.error?.message && (
							<Form.Control.Feedback type="invalid">
								{fieldState.error?.message}
							</Form.Control.Feedback>
						)}
						{errors && errors[name] && (
							<Form.Control.Feedback type="invalid">
								{errors[name]['message']}
							</Form.Control.Feedback>
						)}
					</Form.Group>
				);
			}}
		/>
	);
}
