import { ApiResponse, ClientList } from '@/types';
import { HttpClient } from '@/data';
import { ConfirmData } from '@/types/ConfirmData';
import { EmployeeBill } from '@/types/Gestao';

const configPublic = {
	headers: {
		Authorization: null,
	},
};

export const ConfirmDataRepository = {
	get api() {
		return HttpClient(false);
	},

	listClients: async function (): Promise<ApiResponse<ClientList>> {
		let config = {
			params: {
				limit: 9999,
			},
		};
		return this.api.get(this.api.endpoints.clients, config);
	},

	get: async function (token: string): Promise<ApiResponse<ConfirmData>> {
		return this.api.get(`${this.api.endpoints.confirmData}/?token=${token}`, configPublic);
	},

	create: async function (data: any): Promise<ApiResponse<ConfirmData>> {
		return this.api.post(`${this.api.endpoints.createConfirmData}/`, data, configPublic);
	},

	createSimpleEmployeeBill: async function (data: any): Promise<ApiResponse<any>> {
		const formData = new FormData();
		for (const k in data) {
			formData.append(k, data[k]);
		}
		const config: any = {
			headers: {
				Authorization: null,
				'content-type': 'multipart/form-data',
			},
		};
		return this.api.post(`${this.api.endpoints.createSimpleEmployeeBill}/`, data, config);
	},

	getToEdit: async function (token: string): Promise<ApiResponse<ConfirmData>> {
		return this.api.get(`${this.api.endpoints.publicEmployeeBillUpdate}${token}`, configPublic);
	},

	update: async function (
		token: string,
		data: any
	): Promise<ApiResponse<EmployeeBill>> {
		const config = {
			...configPublic,
			headers: {
				...configPublic.headers,
				'Content-Type': 'multipart/form-data',
			},
		};
		return this.api.patch(
			`${this.api.endpoints.publicEmployeeBillUpdate}${token}/`,
			data,
			config
		);
	},
};
